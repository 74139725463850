import {PublicClientApplication} from '@azure/msal-browser';
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_OAUTH_AZURE_ID,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ['user.read', 'email', 'profile'],
};
