import {breakpointsTailwind, useBreakpoints} from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind);

export default {
    computed: {
        breakpointLgAndGreater() {
            return breakpoints.greater('lg').value;
        },
        breakpointXlAndGreater() {
            return breakpoints.greater('xl').value;
        },
        breakpoint2XlAndGreater() {
            return breakpoints.greater('2xl').value;
        },
    },
};
