<template>
    <div class="flex fixed top-0 justify-center w-full">
        <div class="px-6 text-white bg-primary rounded-b-lg">
            <span
                v-text="
                    __('app.administration.user.actions.impersonate.active_info', {
                        '{{ name }}': username,
                        '{{ account }}': accountName,
                    })
                "
            />
            <a
                class="text-gray hover:text-white"
                href="#"
                @click.prevent="reset"
                v-text="__('app.administration.user.actions.impersonate.leave')"
            />
        </div>
    </div>
</template>

<script>
import {useImpersonatingStore} from '../../stores/impersonatingStore';
import {mapActions} from 'pinia';

export default {
    props: {
        username: {
            type: String,
            required: true,
        },
        accountName: {
            type: String,
            required: true,
        },
    },
    methods: {
        ...mapActions(useImpersonatingStore, ['reset']),
    },
};
</script>
