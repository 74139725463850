import {msalInstance} from '../../api/authentication/msalAuthConfig';
import {useSecurityStore} from '../../stores/securityStore';
import {debug} from '../../shared/helpers/debug';
import {useInformationStore} from '../../stores/informationStore';

/**
 *
 * @param {RouteLocationNormalized} to
 * @return {NavigationGuardResult}
 */
export async function handleMsal(to) {
    const securityStore = useSecurityStore();

    if (
        !securityStore.msal ||
        to.name === 'Logout' ||
        securityStore.blockMsal ||
        securityStore.msalHandled
    ) {
        return;
    }

    debug.log('special msal handling');

    const redirectResponse = await msalInstance.handleRedirectPromise().catch(error => {
        debug.log('error in handleRedirectPromise', error);
    });

    const {refresh} = useInformationStore();

    if (redirectResponse) {
        securityStore.token = redirectResponse.accessToken;
        securityStore.authType = 'MS-Access-Token';

        securityStore.msalHandled = true;

        const success = await refresh();

        return success ? undefined : {name: 'Login'};
    }

    const accounts = msalInstance.getAllAccounts();
    const accessTokenRequest = {
        scopes: ['user.read'],
        account: accounts[0],
    };

    const accessTokenResponse = await msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .catch(async error => {
            debug.log(error);

            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (
                error.errorCode === 'consent_required' ||
                error.errorCode === 'interaction_required' ||
                error.errorCode === 'login_required'
            ) {
                await msalInstance.loginRedirect().catch(error => {
                    debug.log(error);
                });
            }
        });

    const accessToken = accessTokenResponse?.accessToken;

    // Acquire token silent success
    if (accessToken) {
        securityStore.token = accessToken;
        securityStore.authType = 'MS-Access-Token';
    }
}
