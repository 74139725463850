<template>
    <svg
        class="fill-current"
        viewBox="0 0 23 22"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20 0H10C8 0 7 1 7 3v7H3c-2 0-3 1-3 3v6c0 2 1 3 3 3h17c2 0 3-1 3-3V3c0-2-1-3-3-3z"
        />
    </svg>
</template>
