<template>
    <button
        class="w-max h-6 focus:outline-none"
        :class="[
            inline ? 'inline' : 'flex items-center',
            {
                'hover:text-hover text-primary': !black && !white && !gray,
                'cursor-not-allowed opacity-50': disabled,
                'text-white hover:text-hover': white,
                'text-black hover:text-hover': black,
                'text-gray hover:text-hover': gray,
            },
        ]"
        :disabled="disabled"
    >
        <slot v-if="!isExternal" name="left" />

        <external-link-icon v-else class="mr-2" />

        <loading-spinner v-if="loading" class="mr-0.5 w-8 h-8" white />

        <span :class="[isExternal ? 'mt-1' : 'mt-0']" v-text="text" />

        <slot name="right" />
    </button>
</template>

<script>
import ExternalLinkIcon from '../icons/ExternalLinkIcon.vue';
import LoadingSpinner from '../LoadingSpinner.vue';

export default {
    components: {LoadingSpinner, ExternalLinkIcon},
    props: {
        text: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        white: {
            type: Boolean,
        },
        black: {
            type: Boolean,
        },
        isExternal: {
            type: Boolean,
        },
        gray: {
            type: Boolean,
        },
        inline: {
            type: Boolean,
        },
        loading: {
            type: Boolean,
        },
    },
};
</script>
