import {defineStore} from 'pinia';
import {useUserFeatureFlagStore} from './userFeatureFlagStore';
import {useTranslationStore} from './translationStore';
import {useMsTeamsStore} from './msTeamsStore';
import {useSecurityStore} from './securityStore';
import {useUserStore} from './userStore';
import {useAccountStore} from './accountStore';
import {useLocationsStore} from './locationsStore';
import {useInformationStore} from './informationStore';

export const useGlobalStore = defineStore('app', () => {
    const securityStore = useSecurityStore();
    const userStore = useUserStore();
    const accountStore = useAccountStore();
    const locationsStore = useLocationsStore();
    const informationStore = useInformationStore();

    function init() {
        useUserFeatureFlagStore();
        useTranslationStore();
        useMsTeamsStore();
    }

    function resetWithExceptions() {
        sessionStorage.clear();

        securityStore.reset();
        userStore.updateUser(null);
        accountStore.updateAccount(null);
        locationsStore.updateLocations(null);
        informationStore.reset();

        const exceptions = ['authorizationUrl', 'translations', 'currentLocale'];

        Object.keys(localStorage).forEach(key => {
            if (exceptions.includes(key)) {
                return;
            }

            localStorage.removeItem(key);
        });
    }

    return {init, resetWithExceptions};
});
