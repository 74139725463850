export default {
    methods: {
        /**
         * @param {UserView} user
         * @param {boolean} email
         * @returns {string}
         */
        getLabelForUser(user, email = false) {
            const label = `${user.firstName} ${user.lastName}`;
            if (!email) {
                return label;
            }

            return label + ' ' + user.email;
        },
        /**
         * @param {UserView} user
         * @returns {string}
         */
        userName(user) {
            return this.__(`${user.firstName} ${user.lastName}`.trim());
        },
    },
};
