<template>
    <div
        v-if="isAuthenticated && visible && isAdmin && !isMSTeams"
        class="flex static top-0 justify-center py-2 w-full bg-warning"
    >
        &nbsp;<template v-if="isTrial">
            <span
                v-text="
                    __('app.license.notice.trial.text', {
                        '%expiredDate%': formatDateWeekday(account.subscription.trialEndDate, true),
                    }) + '&nbsp;'
                "
            />
            <router-link
                :class="{'no-underline': currentRouteName === 'AdminPricingTable'}"
                :to="{name: 'AdminPricingTable', params: {account: account.id}}"
            >
                <button-link :text="__('app.license.notice.trial.link_text')">
                    <template #right>
                        <arrow-icon class="inline ml-2" direction="right" />
                    </template>
                </button-link>
            </router-link>
        </template>
        <template v-else>
            <span v-text="__('app.license.notice.expired.text') + '&nbsp;'" />
            <router-link
                v-cy="'license-expired-link'"
                :class="{'no-underline': currentRouteName === 'AdminPricingTable'}"
                :to="{name: 'AdminPricingTable', params: {account: account.id}}"
            >
                <button-link :text="__('app.license.notice.expired.link_text')">
                    <template #right>
                        <arrow-icon class="inline ml-2" direction="right" />
                    </template>
                </button-link>
            </router-link>
        </template>
    </div>
</template>

<script>
import ButtonLink from '../../shared/components/core/ButtonLink.vue';
import ArrowIcon from '../../shared/components/icons/ArrowIcon.vue';
import configDay from '../../shared/mixins/configDay';
import {useUserStore} from '../../stores/userStore';
import {mapState} from 'pinia';
import {useAccountStore} from '../../stores/accountStore';
import {useMsTeamsStore} from '../../stores/msTeamsStore';

export default {
    components: {ButtonLink, ArrowIcon},
    mixins: [configDay],
    computed: {
        ...mapState(useUserStore, ['user', 'isAdmin', 'isAuthenticated']),
        ...mapState(useAccountStore, ['account', 'accountHasActiveLicense', 'isTrial']),
        ...mapState(useMsTeamsStore, ['isMSTeams']),
        visible() {
            return !this.accountHasActiveLicense;
        },
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>
