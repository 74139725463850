<template>
    <div
        class="group flex relative justify-center items-center tracking-wide rounded-full cursor-pointer"
    >
        <button
            class="flex justify-center items-center w-full h-full text-black dark:text-white whitespace-nowrap dark:bg-black-light rounded-full focus:outline-none"
            type="button"
        >
            <slot name="button" />
        </button>
        <div class="hidden group-hover:block absolute z-500" :class="openingDirectionStyles">
            <div class="p-3 bg-background-lighter dark:bg-black rounded-md group-hover:shadow-menu">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        opensTowards: {
            type: String,
            default: 'bottom',
        },
    },
    computed: {
        openingDirectionStyles() {
            switch (this.opensTowards) {
                case 'bottom':
                    return 'bottom-0 right-0 pt-3 translate-y-full';
                case 'right':
                    return 'right-0 bottom-0 pl-3 translate-x-full';
                case 'top':
                    return 'top-0 left-0 pb-3 -translate-y-full';
                default:
                    return 'left-0 bottom-0 pr-3 -translate-x-full';
            }
        },
    },
};
</script>
