<template>
    <svg
        height="40"
        viewBox="0 0 141.653 40"
        width="141.653"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <defs>
            <linearGradient
                id="linear-gradient"
                gradientUnits="objectBoundingBox"
                x1="-0.055"
                x2="1.802"
                y1="2.999"
                y2="-5.365"
            >
                <stop offset="0" stop-color="#1999f1" />
                <stop offset="0.427" stop-color="#1946b9" />
            </linearGradient>
            <linearGradient
                id="linear-gradient-2"
                x1="-0.06"
                x2="1.798"
                xlink:href="#linear-gradient"
                y1="3.02"
                y2="-5.344"
            />
            <linearGradient
                id="linear-gradient-3"
                x1="-1.518"
                x2="3.311"
                xlink:href="#linear-gradient"
                y1="3.995"
                y2="-4.369"
            />
            <linearGradient
                id="linear-gradient-4"
                x1="-0.38"
                x2="4.449"
                xlink:href="#linear-gradient"
                y1="2.024"
                y2="-6.34"
            />
        </defs>
        <g id="Gruppe_260" data-name="Gruppe 260" transform="translate(-289.508 -10)">
            <g id="Gruppe_259" data-name="Gruppe 259">
                <path
                    id="Pfad_128"
                    class="fill-current"
                    :class="classes"
                    d="M341.92,21.6a9.633,9.633,0,0,1,3.84.88V10.76a.787.787,0,0,1,.76-.76h2.32a.787.787,0,0,1,.76.76V37.24a.787.787,0,0,1-.76.76h-1.2c-.36,0-.6-.28-.72-.68l-.32-1.16a7.151,7.151,0,0,1-5.08,2.24c-4.24,0-7.48-3.8-7.48-8.4C334.04,25.32,337.48,21.6,341.92,21.6ZM342,34.96a4.3,4.3,0,0,0,3.8-2.4V26.04a6.637,6.637,0,0,0-3.48-.96A4.647,4.647,0,0,0,337.76,30C337.76,32.68,339.44,34.96,342,34.96Z"
                    data-name="Pfad 128"
                    fill="#fff"
                />
                <path
                    id="Pfad_129"
                    class="fill-current"
                    :class="classes"
                    d="M359.866,21.6a7.2,7.2,0,0,1,7.36,7.4,10.058,10.058,0,0,1-.08,1.08.736.736,0,0,1-.721.68H355.546a4.632,4.632,0,0,0,4.6,4.28,6.824,6.824,0,0,0,3.56-1.16c.479-.28.76-.44,1.079-.04l1.121,1.52a.621.621,0,0,1-.081,1.04,9.186,9.186,0,0,1-5.919,2c-4.84,0-8.041-3.8-8.041-8.4C351.865,25.48,355.066,21.6,359.866,21.6Zm3.64,6.64a3.786,3.786,0,0,0-3.681-3.56,4.049,4.049,0,0,0-4.08,3.56Z"
                    data-name="Pfad 129"
                    fill="#fff"
                />
                <path
                    id="Pfad_130"
                    class="fill-current"
                    :class="classes"
                    d="M368.363,35.92l.84-1.56a.69.69,0,0,1,1-.2,8.287,8.287,0,0,0,3.72,1.04c1.2,0,1.96-.48,1.96-1.36,0-1.08-.88-1.6-3.08-2.6-2.48-1.08-4.56-2.6-4.56-5.24,0-2,1.64-4.4,5.48-4.4a8.967,8.967,0,0,1,4.72,1.24.893.893,0,0,1,.32,1.16l-.72,1.36a.841.841,0,0,1-1.04.32,9.136,9.136,0,0,0-3.28-.84c-1.36,0-1.8.6-1.8,1.2,0,1.04,1,1.48,2.56,2.16,2.919,1.28,5.319,2.52,5.319,5.52,0,2.48-2.36,4.68-5.919,4.68a8.391,8.391,0,0,1-5.281-1.64A.673.673,0,0,1,368.363,35.92Z"
                    data-name="Pfad 130"
                    fill="#fff"
                />
                <path
                    id="Pfad_131"
                    class="fill-current"
                    :class="classes"
                    d="M381.816,10.76a.787.787,0,0,1,.76-.76h2.32a.787.787,0,0,1,.76.76V28.08l6.24-5.6a1.579,1.579,0,0,1,1-.48h2.8a.621.621,0,0,1,.44,1.08L389.5,29.12l7.839,7.84a.592.592,0,0,1-.44,1.04h-3.279a1.014,1.014,0,0,1-.72-.32l-7.24-7.44v7a.787.787,0,0,1-.76.76h-2.32a.787.787,0,0,1-.76-.76Z"
                    data-name="Pfad 131"
                    fill="#fff"
                />
                <path
                    id="Pfad_132"
                    class="fill-current"
                    :class="classes"
                    d="M402.018,32.84a2.78,2.78,0,1,1-2.76,2.8A2.783,2.783,0,0,1,402.018,32.84Z"
                    data-name="Pfad 132"
                    fill="#fff"
                />
                <path
                    id="Pfad_133"
                    class="fill-current"
                    :class="classes"
                    d="M407.618,10.76a.787.787,0,0,1,.76-.76h2.28a.787.787,0,0,1,.76.76V37.24a.787.787,0,0,1-.76.76h-2.28a.787.787,0,0,1-.76-.76Z"
                    data-name="Pfad 133"
                    fill="#fff"
                />
                <path
                    id="Pfad_134"
                    class="fill-current"
                    :class="classes"
                    d="M413.459,23.04a.706.706,0,0,1,.68-1.04h2.64a.869.869,0,0,1,.68.44l4.96,10.64h.04l4.8-10.64a.805.805,0,0,1,.84-.44h2.28a.685.685,0,0,1,.68,1.04l-12.24,26.48a.8.8,0,0,1-.68.48h-2.4a.716.716,0,0,1-.68-1.08l5.36-11.48Z"
                    data-name="Pfad 134"
                    fill="#fff"
                />
            </g>
            <path
                id="Pfad_135"
                d="M308.027,27.837a3.776,3.776,0,0,0,1.033-1.932,3.8,3.8,0,0,0,0-1.473,3.777,3.777,0,0,0-3.7-3.038H293.282a3.776,3.776,0,0,0-3.7,3.038,3.8,3.8,0,0,0,0,1.473,3.779,3.779,0,0,0,2.257,2.75,3.755,3.755,0,0,0,1.445.287h12.076a3.755,3.755,0,0,0,1.445-.287,3.794,3.794,0,0,0,1.224-.818Z"
                data-name="Pfad 135"
                fill="url(#linear-gradient)"
            />
            <path
                id="Pfad_136"
                d="M326.2,33.536a3.779,3.779,0,0,0-.348-.652,3.735,3.735,0,0,0-.47-.572,3.68,3.68,0,0,0-.572-.469,3.747,3.747,0,0,0-.652-.349,3.755,3.755,0,0,0-1.444-.287H310.642a3.773,3.773,0,0,0-3.487,5.218,3.748,3.748,0,0,0,.349.652,3.753,3.753,0,0,0,1.694,1.39,3.781,3.781,0,0,0,1.444.288h12.077a3.774,3.774,0,0,0,3.486-5.219Z"
                data-name="Pfad 136"
                fill="url(#linear-gradient-2)"
            />
            <path
                id="Pfad_137"
                d="M313.194,26.613a3.781,3.781,0,0,0,2.042,2.042,3.771,3.771,0,0,0,2.889,0,3.776,3.776,0,0,0,2.257-2.75,3.8,3.8,0,0,0,0-1.473,3.774,3.774,0,0,0-7.4,0,3.752,3.752,0,0,0,0,1.473A3.731,3.731,0,0,0,313.194,26.613Z"
                data-name="Pfad 137"
                fill="url(#linear-gradient-3)"
            />
            <path
                id="Pfad_138"
                d="M302.807,33.536a3.776,3.776,0,1,0-4.931,4.931,3.783,3.783,0,0,0,3.541-.348,3.788,3.788,0,0,0,1.39-1.694,3.787,3.787,0,0,0,0-2.889Z"
                data-name="Pfad 138"
                fill="url(#linear-gradient-4)"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        classes: {
            type: String,
            default: '',
        },
    },
};
</script>
