import {defineStore} from 'pinia';
import {useLocalStorage} from '@vueuse/core';
import moment from 'moment-timezone';

export const useVersionToastTimestampStore = defineStore('versionToastTimestamp', () => {
    const timestamp = useLocalStorage('versionToastTimestamp', null);

    function setTimestamp() {
        timestamp.value = moment();
    }

    function isToastMuted() {
        if (!timestamp.value) {
            return false;
        }

        return moment().diff(timestamp.value, 'minutes') < 10;
    }

    return {
        timestamp,
        setTimestamp,
        isToastMuted,
    };
});
