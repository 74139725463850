export default {
    computed: {
        /**
         * @returns {object[]}
         */
        resourceTypes() {
            return this.$store.state.resources.resourceTypes || [];
        },
        /**
         * @returns {object|null}
         */
        selectedResourceType() {
            if (this.resourceTypes.length === 1) {
                return this.resourceTypes[0];
            }
            return this.resourceTypes.find(({selected}) => selected);
        },
    },
    methods: {
        /**
         * @param {string|null} resourceKey
         */
        selectResource(resourceKey) {
            this.$store.dispatch('resources/selectResource', resourceKey);
        },
        /**
         * @param {string} resourceKey
         * @returns {string}
         */
        resourceDayBookingType(resourceKey) {
            if (resourceKey === 'zone') {
                return 'ZoneDayBooking';
            }

            if (resourceKey === 'meetingRoom') {
                return 'MeetingRoomDayBooking';
            }

            return 'SeatDayBooking';
        },
    },
};
