export default class AdminFeatures {
    constructor(json) {
        this.adminDashboard = json['admin_dashboard'];
        this.anonymization = json['anonymization'];
        this.presentList = json['present_list'];
        this.favoriteSeat = json['favorite_seat'];
        this.singleSignOn = json['single_sign_on'];
        this.calendarIntegration = json['calendar_integration'];
        this.bookForOthers = json['book_for_others'];
        this.whitelabeling = json['whitelabeling'];
        this.userManagement = json['user_management'];
        this.oidcLogin = json['oidc_login'];
        this.zoneBookings = json['zone_bookings'];
        this.meetingRoomBookings = json['meeting_room_bookings'];
    }
}
