<template>
    <vue-final-modal
        v-if="visible && isAuthenticated"
        v-model="visible"
        classes="flex justify-center items-center"
        :click-to-close="falseValue"
        content-class="justify-center w-full md:w-133 relative flex flex-col h-full md:h-auto md:max-h-600 overflow-y-auto px-7 pt-5 pb-7 rounded-md bg-background-light"
        :esc-to-close="falseValue"
        overlay-class="!bg-background-lighter opacity-70"
        :transition="{
            'enter-active-class': 'transition duration-200 ease-in-out transform',
            'enter-class': 'translate-y-full',
            'enter-to-class': 'translate-y-0',
            'leave-active-class': 'transition duration-200 ease-in-out transform',
            'leave-to-class': 'translate-y-full',
            'leave-class': 'translate-y-0',
        }"
    >
        <div class="flex flex-col items-center w-full">
            <loading-spinner class="mb-5 w-12 h-12" />
            <span class="mb-2.5 text-2xl" v-text="__('app.loading_modal.title')" />
            <vue-writer
                :array="loadingMessages"
                class="mb-2.5"
                cursor="underscore"
                :erase-speed="10"
                :type-speed="50"
            />
        </div>
    </vue-final-modal>
</template>

<script>
import userHelper from '../mixins/userHelper';
import LoadingSpinner from './LoadingSpinner.vue';
import VueWriter from 'vue-writer';
import {useUserStore} from '../../stores/userStore';
import {mapState} from 'pinia';

export default {
    components: {LoadingSpinner, VueWriter},
    mixins: [userHelper],
    data: () => ({
        visible: true,
        falseValue: false,
    }),
    computed: {
        ...mapState(useUserStore, ['isAuthenticated']),
        loadingMessages() {
            const array = [
                this.__('app.loading_modal.loading_messages.seats'),
                this.__('app.loading_modal.loading_messages.tables'),
                this.__('app.loading_modal.loading_messages.present'),
                this.__('app.loading_modal.loading_messages.rinse'),
                this.__('app.loading_modal.loading_messages.air'),
                this.__('app.loading_modal.loading_messages.playing'),
            ];

            return array.sort(() => 0.5 - Math.random());
        },
    },
};
</script>
