<template>
    <div class="flex flex-col">
        <div
            class="flex relative h-14 text-black dark:text-white bg-background-lighter dark:bg-black-light rounded-md custom-select"
            :class="{'border border-error': errors?.length}"
        >
            <select
                ref="select"
                v-model="selected"
                :class="cssClasses"
                :disabled="disabled"
                :name="name"
                :required="required"
                @change="valueChange(selected)"
            >
                <option :disabled="!allowDefault" :value="null" v-text="label" />
                <option
                    v-for="(option, index) in options"
                    :key="index"
                    :disabled="option.disabled ?? false"
                    :value="option"
                >
                    <slot name="option" :option="option">
                        {{ option.name }}
                    </slot>
                </option>
            </select>
            <div
                class="flex absolute right-0 justify-center items-center mr-5 h-14 pointer-events-none"
                :class="disabled ? '' : 'z-20'"
            >
                <arrow-icon
                    class="dark:text-accent"
                    :class="disabled ? 'text-gray' : 'text-primary'"
                    direction="down"
                />
            </div>
        </div>
        <span
            v-for="error of errors"
            :key="error.$uid"
            class="pt-1 w-full text-sm text-error"
            v-html="error.$message"
        />
    </div>
</template>

<script>
import ArrowIcon from '../icons/ArrowIcon.vue';

export default {
    components: {ArrowIcon},
    props: {
        label: {
            type: String,
            default: '',
        },
        setSelect: {
            type: [Object, String],
            default: null,
        },
        options: {
            type: [Array, Number],
            default: () => [],
        },
        customBackgroundClasses: {
            type: String,
            default: '',
        },
        selectClasses: {
            type: String,
            default: '',
        },
        required: {
            type: Boolean,
        },
        allowDefault: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        name: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['setSelected'],
    data: () => ({
        selected: null,
    }),
    computed: {
        cssClasses() {
            let classes = this.customBackgroundClasses
                ? this.customBackgroundClasses
                : 'bg-background-lighter dark:bg-black-light';

            classes =
                classes + ' absolute pl-5 w-full h-14 bg-none rounded-md ' + this.selectClasses;

            return this.disabled
                ? classes + 'cursor-default opacity-70'
                : classes + ' z-10 cursor-pointer';
        },
    },
    watch: {
        setSelect(selected) {
            this.selected = selected;
        },
        options(options) {
            if (
                this.selected !== null &&
                this.selected.id &&
                !options.find(option => option.id === this.selected.id)
            ) {
                this.selected = null;
                this.valueChange(null);
            }
        },
    },
    created() {
        if (
            this.setSelect &&
            Object.keys(this.setSelect).length === 0 &&
            this.setSelect.constructor === Object
        ) {
            return;
        }

        this.selected = this.setSelect;
    },
    methods: {
        valueChange(selected) {
            this.$emit('setSelected', selected);
        },
    },
};
</script>
