<template>
    <dropdown-hover
        :class="{'hover:bg-background-lighter': isAdminArea}"
        :opens-towards="opensTowards"
    >
        <template #button>
            <globe-icon v-cy="'language-dropdown'" class="group-hover:text-hover" />
        </template>
        <template #content>
            <div
                class="flex"
                :class="{
                    'flex-col': opensTowards === 'bottom',
                    'flex-col-reverse ': opensTowards === 'top',
                    'flex-row gap-2': opensTowards === 'right',
                    'flex-row-reverse gap-2': opensTowards === 'left',
                }"
            >
                <div
                    v-for="(language, index) in availableLanguages"
                    :key="index"
                    v-cy="'language-option-' + language"
                    @click="updateLocale(language)"
                >
                    <span
                        v-if="isWindows"
                        class="cursor-pointer"
                        :class="{'font-bold': language === currentLocale}"
                        @click="updateLocale(language)"
                        v-text="language.toUpperCase()"
                    />
                    <span
                        v-else
                        class="text-3xl cursor-pointer"
                        v-html="getLanguageCode(language)"
                    />
                </div>
            </div>
        </template>
    </dropdown-hover>
</template>

<script>
import GlobeIcon from '../../icons/GlobeIcon.vue';
import DropdownHover from '../../core/DropdownHover.vue';
import {useLocaleStore} from '../../../../stores/localeStore';
import {mapActions, mapState} from 'pinia';

export default {
    components: {
        DropdownHover,
        GlobeIcon,
    },
    props: {
        opensTowards: {
            type: String,
            default: 'bottom',
        },
        isAdminArea: Boolean,
    },
    computed: {
        ...mapState(useLocaleStore, ['currentLocale', 'availableLanguages']),
        isWindows() {
            const userAgent = window.navigator.userAgent;

            return userAgent.toLocaleLowerCase().includes('windows');
        },
    },
    methods: {
        ...mapActions(useLocaleStore, ['updateLocale']),
        getLanguageCode(language) {
            switch (language) {
                case 'en':
                    return '&#127468;&#127463;';
                case 'it':
                    return '&#127470;&#127481;';
                case 'fr':
                    return '&#127467;&#127479;';
                case 'es':
                    return '&#127466;&#127480;';
                default:
                    return '&#127465;&#127466;';
            }
        },
    },
};
</script>
