<template>
    <div class="flex VueTables__limit-field">
        <single-select
            :id="props.selectAttrs.id"
            class="mr-3.5 mb-3.5 min-w-320"
            :options="props.perPageValues"
            :set-select="props.selectAttrs.value"
            @set-selected="props.selectEvents.change"
        >
            <template #option="{option}">
                {{
                    __('app.administration.pagination.amount', {
                        '%amount%': option,
                        '%label%': props.opts.label,
                    })
                }}
            </template>
        </single-select>

        <!--totalCount comes in the moment only for the user table ('null' in the other tables). If another table rows
        count is needed, this should be customized-->
        <div v-if="!!totalCount" class="flex gap-1.5 items-center mb-3.5">
            <span class="font-bold" v-text="totalCount" />
            <span v-text="__('app.administration.user.table.thead.user_count')" />
        </div>
    </div>
</template>

<script>
import SingleSelect from '../../../../shared/components/core/SingleSelect.vue';

export default {
    name: 'MyPerPageSelector',
    components: {SingleSelect},
    //eslint-disable-next-line vue/require-prop-types
    props: ['props'],
    computed: {
        totalCount() {
            // hacky way but there is no other option
            return this.$parent.$attrs['total-count'];
        },
    },
};
</script>
