import PrimaryRoomView from './PrimaryRoomView';

export default class UserView {
    static ROLE_USER = 'ROLE_USER';
    static ROLE_ANALYST = 'ROLE_ANALYST';
    static ROLE_MANAGER = 'ROLE_MANAGER';
    static ROLE_ADMIN = 'ROLE_ADMIN';
    static ROLE_SUPERADMIN = 'ROLE_SUPERADMIN';

    constructor(json) {
        this.id = json.id;
        this.email = json.email;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.accountType = json.accountType;
        this.roles = json.roles;
        this.anonymized = json.anonymized;
        this.legacyIcal = json.legacyIcal;
        this.calendarNotification = json.calendarNotification;
        this.primaryRoom = null;
        if (json.primaryRoom) {
            this.primaryRoom = new PrimaryRoomView(json.primaryRoom);
        }
        this.timezone = json.timezone;
        this.gravatar = json.gravatar;
        this.avatarName = json.avatarName ?? '';
        this.profileImage = json.profileImage;
        this.licenseActive = json.licenseActive;
        this.initials = json.initials;
        this.komboAvatarUrl = json.komboAvatarUrl;
        this.isKomboUser = json.isKomboUser;
    }
}
