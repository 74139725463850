<template>
    <svg class="fill-current" viewBox="0 0 34 22" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(-972 -883)">
            <path d="M995 889v10c0 3.3-2.7 6-6 6s-6-2.7-6-6v-10c0-3.3 2.7-6 6-6s6 2.7 6 6z" />
            <circle cx="976" cy="887" r="4" />
            <circle cx="976" cy="901" r="4" />
            <circle cx="1002" cy="887" r="4" />
            <circle cx="1002" cy="901" r="4" />
        </g>
    </svg>
</template>
