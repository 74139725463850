<template>
    <div class="flex py-2.5">
        <span v-text="__('app.login.footer.language')" />
        <select class="p-0 ml-1 bg-none" :value="currentLocale" @change="adjustLanguage">
            <option
                v-for="(language, index) in availableLanguages"
                :key="index"
                :value="language"
                v-text="language.toUpperCase()"
            />
        </select>
    </div>
</template>

<script>
import {useLocaleStore} from '../../../../stores/localeStore';
import {mapActions, mapState} from 'pinia';

export default {
    computed: {
        ...mapState(useLocaleStore, ['currentLocale', 'availableLanguages']),
    },
    methods: {
        ...mapActions(useLocaleStore, ['updateLocale']),
        adjustLanguage(event) {
            this.updateLocale(event.target.value);
        },
    },
};
</script>
