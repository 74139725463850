/* eslint-disable no-undef, no-console */
export const debug = {
    active: import.meta.env.DEV,
    start: group => {
        if (debug.active) {
            console.groupCollapsed(group);
        }
    },
    end: group => {
        if (debug.active) {
            console.groupEnd(group);
        }
    },
    log: (...data) => {
        if (debug.active) {
            data.forEach(entry => console.log(entry));
        }
    },
    error: (...data) => {
        if (debug.active) {
            data.forEach(entry => console.error(entry));
        }
    },
};

/* eslint-enable no-undef, no-console */
