<template>
    <form action="#" class="flex flex-col items-start mr-2 VueTables__search-field" @submit.prevent>
        <label
            :class="`${props.theme.label} mb-2.5`"
            :for="`VueTables__search_${props.id}`"
            v-text="__('app.administration.search.label')"
        />

        <input
            :id="`VueTables__search_${props.id}`"
            ref="filter"
            autocomplete="off"
            class="!mb-3.5 form-input medium search"
            :placeholder="props.opts.searchPlaceholder"
            type="text"
            @keyup="search"
        />
    </form>
</template>

<script>
export default {
    name: 'MyGenericFilter',
    //eslint-disable-next-line vue/require-prop-types
    props: ['props'],
    data: () => ({
        timeOut: null,
    }),
    methods: {
        focus() {
            this.$refs.filter.focus();
        },
        blur() {
            this.$refs.filter.blur();
        },
        search(option) {
            clearTimeout(this.timeOut);
            this.timeOut = setTimeout(() => {
                this.props.search(this.props.opts.debounce)(option);
            }, 500);
        },
    },
};
</script>
