<template>
    <div
        class="flex overflow-y-scroll fixed inset-0 z-50 flex-col w-full h-full bg-white dark:bg-black transition origin-top-right"
    >
        <div class="flex flex-col space-y-6 h-1/2">
            <div class="px-5 pb-2.5 border-b-2 border-background">
                <template v-if="isAuthenticated">
                    <div class="flex justify-between items-center pt-6 pb-2.5">
                        <router-link
                            exact-active-class="link-active"
                            :to="{name: 'Home'}"
                            @click="close"
                        >
                            <h1 v-text="__('app.header.start')" />
                        </router-link>
                        <close-icon class="text-black dark:text-white" @click="close" />
                    </div>
                    <template v-if="activeLicense">
                        <router-link
                            v-for="(resourceType, index) in resourceTypes"
                            :key="index"
                            v-cy="`menu_book_${resourceType.key}`"
                            :to="{
                                name: resourceDayBookingType(resourceType.key),
                            }"
                            @click="selectResource(resourceType.key)"
                        >
                            <h1 class="py-2.5" v-text="__(`app.header.book_${resourceType.key}`)" />
                        </router-link>
                        <router-link :to="{name: 'PresentList'}" @click="close">
                            <h1 class="py-2.5" v-text="__('app.header.present')" />
                        </router-link>
                        <router-link :to="{name: 'DayBookingOverview'}" @click="close">
                            <h1 class="py-2.5" v-text="__('app.header.dayBooking_overview')" />
                        </router-link>
                        <router-link :to="{name: 'FavoriteSeats'}" @click="close">
                            <h1 class="py-2.5" v-text="__('app.header.fav_seats')" />
                        </router-link>
                        <router-link :to="{name: 'UserSettings'}" @click="close">
                            <h1 class="py-2.5" v-text="__('app.header.settings')" />
                        </router-link>
                    </template>
                    <a
                        :href="`https://www.desk.ly/${currentLocale}/help`"
                        target="_blank"
                        @click="close"
                    >
                        <h1 class="py-2.5" v-text="__('app.header.help')" />
                    </a>
                    <router-link v-if="!isMSTeams" :to="{name: 'Logout'}" @click="close">
                        <h1 class="py-2.5" v-text="__('app.header.logout')" />
                    </router-link>
                </template>

                <template v-else>
                    <div class="flex justify-between items-center pt-6 pb-2.5">
                        <router-link :to="{name: 'Login'}" @click="close">
                            <h1 v-text="__('app.header.start')" />
                        </router-link>
                        <close-icon class="text-black dark:text-white" @click="close" />
                    </div>
                    <router-link :to="{name: 'ForgotPassword'}" @click="close">
                        <h2 class="py-2.5" v-text="__('app.login.forgot_password.heading')" />
                    </router-link>
                    <router-link :to="{name: 'RegisterUser'}" @click="close">
                        <h2 class="py-2.5" v-text="__('app.login.register.heading')" />
                    </router-link>
                    <router-link :to="{name: 'RegisterAccount'}" @click="close">
                        <h2 class="py-2.5" v-text="__('app.login.registerAccount.heading')" />
                    </router-link>
                </template>
            </div>
            <div class="flex flex-col !mt-2.5">
                <div class="px-5 mb-16">
                    <language-dropdown-mobile v-if="!isMSTeams" />
                    <router-link
                        v-if="receiveNotifications.isActive"
                        v-cy="'mobile-notification-menu-item'"
                        class="block py-2.5"
                        :to="{name: 'Archive'}"
                        @click="close"
                    >
                        <button-link black :text="__('app.notifications.title')">
                            <template #left>
                                <div class="pr-1 mr-[5px] ml-[3px] w-5">
                                    <bell-icon
                                        indicator-class="border-white"
                                        :show-indicator="hasUnreadNotifications"
                                    />
                                </div>
                            </template>
                        </button-link>
                    </router-link>
                    <a
                        class="block py-2.5"
                        :href="`https://www.desk.ly/${supportedLocale}/`"
                        target="_blank"
                        @click="close"
                    >
                        <button-link black is-external :text="__('app.header.about')" />
                    </a>
                    <a
                        class="block py-2.5"
                        :href="`https://www.desk.ly/${supportedLocale}/legal`"
                        target="_blank"
                        @click="close"
                    >
                        <button-link black is-external :text="__('app.header.imprint')" />
                    </a>
                    <a
                        class="block py-2.5"
                        :href="`https://www.desk.ly/${supportedLocale}/privacy`"
                        target="_blank"
                        @click="close"
                    >
                        <button-link black is-external :text="__('app.header.privacy_policy')" />
                    </a>
                    <a
                        class="block py-2.5"
                        :href="`https://www.desk.ly/${supportedLocale}/terms`"
                        target="_blank"
                        @click="close"
                    >
                        <button-link
                            black
                            is-external
                            :text="__('app.header.general_business_terms')"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CloseIcon from '../../../components/icons/CloseIcon.vue';
import resourceTypes from '../../../mixins/resourceTypes';
import userHelper from '../../../mixins/userHelper';
import ButtonLink from '../../core/ButtonLink.vue';
import LanguageDropdownMobile from './LanguageDropdownMobile.vue';
import BellIcon from '../../icons/BellIcon.vue';
import {mapState} from 'pinia';
import {useNotificationStore} from '../../../../stores/notificationStore';
import {useFeatureFlagStore} from '../../../../stores/featureFlagStore';
import {useLocaleStore} from '../../../../stores/localeStore';
import {useUserStore} from '../../../../stores/userStore';

export default {
    components: {
        BellIcon,
        ButtonLink,
        CloseIcon,
        LanguageDropdownMobile,
    },
    mixins: [userHelper, resourceTypes],
    props: {
        isMSTeams: {
            type: Boolean,
        },
        activeLicense: {
            type: Boolean,
        },
    },
    emits: ['close'],
    computed: {
        ...mapState(useNotificationStore, ['hasUnreadNotifications']),
        ...mapState(useFeatureFlagStore, ['receiveNotifications']),
        ...mapState(useLocaleStore, ['currentLocale', 'supportedLocale']),
        ...mapState(useUserStore, ['isAuthenticated']),
    },
    methods: {
        close() {
            this.$emit('close');
        },
        selectResource(key) {
            this.$store.dispatch('resources/selectResource', key);
            this.close();
        },
    },
};
</script>
