<template>
    <svg
        class="fill-current"
        viewBox="0 0 22 22"
        x="0"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        y="0"
    >
        <g transform="rotate(90 793 79)">
            <path d="M718 853h14c2 0 4 2 4 4s-2 4-4 4h-14c-2 0-4-2-4-4s2-4 4-4z" />
            <circle cx="725" cy="867" r="4" />
        </g>
    </svg>
</template>
