import {createRouter, createWebHistory} from 'vue-router';
import UserView from '../api/models/view/UserView.js';
import {getCurrentLocale} from '../stores/localeStore';
import {authenticationCallbackRoute} from './routeActions/authenticationCallback';
import {confirmUser} from './routeActions/confirmUser';
import {PaymentCanceled} from './routeActions/paymentCanceled';
import {logoutMicrosoft} from './routeActions/logoutMicrosoft';
import {requireAuth} from './guards/requireAuth';
import {requireLicense} from './guards/requireLicense';
import {useAccountStore} from '../stores/accountStore';
import {useUserStore} from '../stores/userStore';
import {checkForTargetRouteAfterLogin} from './guards/checkForTargetRouteAfterLogin';
import {wrapRouteGuards} from './guardHelper';
import {resendSSOVerification} from './routeActions/resendSSOVerification';
import {useFeatureFlagStore} from '../stores/featureFlagStore';

const routes = [
    {
        path: '/',
        beforeEnter: [requireAuth, checkForTargetRouteAfterLogin],
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import('../frontstage/components/home/HomeView.vue'),
            },
            {
                path: '/archive',
                name: 'Archive',
                component: () => import('../frontstage/components/archive/ArchiveView.vue'),
                beforeEnter: () => {
                    return useFeatureFlagStore().receiveNotifications.isReady();
                },
            },
            {
                path: '',
                beforeEnter: [requireLicense],
                children: [
                    {
                        path: '/settings',
                        children: [
                            {
                                path: '',
                                name: 'UserSettings',
                                component: () =>
                                    import('../frontstage/components/settings/UserSettings.vue'),
                            },
                            {
                                path: 'booking',
                                name: 'BookingSettings',
                                component: () =>
                                    import('../frontstage/components/settings/BookingSettings.vue'),
                                meta: {featureFlag: 'bookForOthers'},
                            },
                            {
                                path: 'booking/add',
                                name: 'BookingSettingsAdd',
                                component: () =>
                                    import(
                                        '../frontstage/components/settings/BookingSettingsAdd.vue'
                                    ),
                                meta: {featureFlag: 'bookForOthers'},
                            },
                            {
                                path: 'appearance',
                                name: 'AppearanceSettings',
                                component: () =>
                                    import(
                                        '../frontstage/components/settings/AppearanceSettings.vue'
                                    ),
                            },
                            {
                                path: 'room',
                                name: 'RoomSettings',
                                component: () =>
                                    import('../frontstage/components/settings/RoomSettings.vue'),
                            },
                            {
                                path: 'week',
                                name: 'WeekSettings',
                                component: () =>
                                    import('../frontstage/components/settings/WeekSettings.vue'),
                            },
                            {
                                path: 'timezone',
                                name: 'TimezoneSettings',
                                component: () =>
                                    import(
                                        '../frontstage/components/settings/TimezoneSettings.vue'
                                    ),
                            },
                            {
                                path: 'password',
                                name: 'PasswordSettings',
                                component: () =>
                                    import(
                                        '../frontstage/components/settings/PasswordSettings.vue'
                                    ),
                            },
                            {
                                path: 'profileImage',
                                name: 'ProfileImageSettings',
                                component: () =>
                                    import(
                                        '../frontstage/components/settings/ProfileImageSettings.vue'
                                    ),
                            },
                            {
                                path: 'shigoto',
                                name: 'ShigotoSettings',
                                component: () =>
                                    import('../frontstage/components/settings/ShigotoSettings.vue'),
                                beforeEnter: () =>
                                    useFeatureFlagStore().showShigotoActions.isReady(),
                            },
                        ],
                    },
                    {
                        path: '/overview/:selectedDay?/:selectedResourceId?/:selectedUserMeBookForId?',
                        name: 'DayBookingOverview',
                        component: () =>
                            import('../frontstage/components/overview/DayBookingOverview.vue'),
                    },
                    {
                        path: '/weekOverview',
                        name: 'WeekOverview',
                        component: () =>
                            import('../frontstage/components/weekOverview/WeekOverview.vue'),
                    },
                    {
                        path: '/confirmation/seat/:seatId?',
                        name: 'DayBookingConfirmationSeat',
                        component: () =>
                            import(
                                '../frontstage/components/seatDayBooking/DayBookingConfirmation.vue'
                            ),
                        props: true,
                    },
                    {
                        path: '/confirmation/zone/:zoneId?/:bookedCapacity?',
                        name: 'DayBookingConfirmationZone',
                        component: () =>
                            import(
                                '../frontstage/components/zoneDayBooking/DayBookingConfirmation.vue'
                            ),
                        props: true,
                    },
                    {
                        path: '/confirmation/meeting-room/:meetingRoomId?',
                        name: 'DayBookingConfirmationMeetingRoom',
                        component: () =>
                            import(
                                '../frontstage/components/meetingRoomDayBooking/DayBookingConfirmation.vue'
                            ),
                        props: true,
                    },
                    {
                        path: '/confirmation/qr/:seatId?',
                        name: 'DeprecatedQrCodeDayBookingConfirmation',
                        component: () =>
                            import(
                                '../frontstage/components/seatDayBooking/DeprecatedQrCodeDayBookingConfirmation.vue'
                            ),
                        props: true,
                    },
                    {
                        //do not remove (qr code backwards compatibility)
                        path: '/confirmation/:seatId?',
                        name: 'DayBookingConfirmationLegacy',
                        redirect: to => ({path: '/confirmation/seat/' + to.params.seatId}),
                        props: true,
                    },
                    {
                        path: 'booking',
                        name: 'Booking',
                        component: () => import('../frontstage/components/booking/BookingView.vue'),
                        beforeEnter: async () => {
                            const isAllowed = await useFeatureFlagStore().newBooking.isReady();

                            if (!isAllowed) {
                                return {name: 'Home'};
                            }
                        },
                    },
                    {
                        path: '/seatDayBooking',
                        name: 'SeatDayBooking',
                        component: () =>
                            import('../frontstage/components/seatDayBooking/SeatDayBooking.vue'),
                    },
                    {
                        path: '/zone/dayBooking',
                        name: 'ZoneDayBooking',
                        component: () =>
                            import('../frontstage/components/zoneDayBooking/ZoneDayBooking.vue'),
                    },
                    {
                        path: '/meetingRoom/dayBooking',
                        name: 'MeetingRoomDayBooking',
                        component: () =>
                            import(
                                '../frontstage/components/meetingRoomDayBooking/MeetingRoomDayBooking.vue'
                            ),
                    },
                    {
                        path: '/favorites',
                        name: 'FavoriteSeats',
                        component: () => import('../frontstage/components/FavoriteSeats.vue'),
                    },
                    {
                        path: '/present/:selectedDay?',
                        name: 'PresentList',
                        component: () => import('../frontstage/components/PresentList.vue'),
                        beforeEnter: () => {
                            //TODO: add global handling after failure
                            const {account} = useAccountStore();
                            return !account.anonymous;
                        },
                    },
                    {
                        path: '/admin',
                        name: 'Admin',
                        component: () => import('../admin/components/AdminView.vue'),
                        meta: {role: UserView.ROLE_ANALYST, hideNavbar: true},
                        redirect: () => ({name: 'AdminDashboard'}),
                        children: [
                            {
                                path: 'dashboard',
                                name: 'AdminDashboard',
                                component: () => import('../admin/components/Dashboard.vue'),
                                meta: {role: UserView.ROLE_ANALYST},
                            },
                            {
                                path: 'analytics',
                                meta: {role: UserView.ROLE_ANALYST},
                                children: [
                                    {
                                        path: 'user-status',
                                        name: 'UserStatusAnalytics',
                                        component: () =>
                                            import(
                                                '../admin/components/UserStatusAnalyticsView.vue'
                                            ),
                                    },
                                    {
                                        path: 'occupancy',
                                        name: 'OccupancyAnalytics',
                                        component: () =>
                                            import(
                                                '../admin/components/OccupancyAnalyticsView.vue'
                                            ),
                                    },
                                    {
                                        path: 'general',
                                        name: 'AdminAnalyticsOld',
                                        component: () =>
                                            import('../admin/components/AdminDashboard.vue'),
                                        beforeEnter: () => {
                                            const expired = new Date() >= new Date('2025-01-15');

                                            if (expired) {
                                                return {name: 'OccupancyAnalytics'};
                                            }
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'dayBookings/:accountId?',
                                name: 'AdminDayBookingList',
                                component: () => import('../admin/components/DayBookingList.vue'),
                                meta: {role: UserView.ROLE_ANALYST},
                            },
                            {
                                path: 'account',
                                name: 'AdminAccountList',
                                component: () => import('../admin/components/AccountList.vue'),
                                meta: {role: UserView.ROLE_SUPERADMIN},
                            },
                            {
                                path: 'account/add',
                                name: 'AdminAccountAdd',
                                component: () => import('../admin/components/forms/AccountAdd.vue'),
                                meta: {role: UserView.ROLE_SUPERADMIN},
                            },
                            {
                                path: 'account/:accountId',
                                name: 'AdminAccountEdit',
                                component: () =>
                                    import('../admin/components/forms/AccountEdit.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'authentication',
                                name: 'AdminAuthenticationEdit',
                                component: () =>
                                    import('../admin/components/forms/AuthenticationEdit.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'calendar-settings',
                                name: 'AdminCalendarSettings',
                                component: () => import('../admin/components/CalendarSettings.vue'),
                            },
                            {
                                path: 'domain/:accountId',
                                name: 'AdminDomainList',
                                component: () => import('../admin/components/DomainList.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'api-keys/:accountId',
                                name: 'AdminAPIKeyTable',
                                component: () =>
                                    import('../admin/components/tables/APIKeyTable.vue'),
                                meta: {adminAuth: true},
                            },
                            {
                                path: 'floor/add/:accountId',
                                name: 'AdminFloorAdd',
                                component: () => import('../admin/components/forms/FloorAdd.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'location/:locationId/floor/:floorId',
                                name: 'AdminFloorEdit',
                                component: () => import('../admin/components/forms/FloorEdit.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'location/add/:accountId',
                                name: 'AdminLocationAdd',
                                component: () =>
                                    import('../admin/components/forms/LocationAdd.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'account/:accountId/location/:locationId',
                                name: 'AdminLocationEdit',
                                component: () =>
                                    import('../admin/components/forms/LocationEdit.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'rooms/add/:accountId',
                                name: 'AdminRoomAdd',
                                component: () => import('../admin/components/forms/RoomAdd.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'locations/:locationId/floor/:floorId/room/:roomId',
                                name: 'AdminRoomEdit',
                                component: () => import('../admin/components/forms/RoomEdit.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'users/:accountId',
                                name: 'AdminUserList',
                                component: () => import('../admin/components/UserList.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                                alias: 'users',
                            },
                            {
                                path: 'users/add/:accountId',
                                name: 'AdminUserAdd',
                                component: () => import('../admin/components/forms/UserAdd.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                                alias: 'users',
                            },
                            {
                                path: 'account/:accountId/users/:userId',
                                name: 'AdminUserEdit',
                                component: () => import('../admin/components/forms/UserEdit.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                                alias: 'users',
                            },
                            {
                                path: 'seat/:accountId',
                                name: 'AdminSeatList',
                                component: () => import('../admin/components/SeatList.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'characteristics/:accountId',
                                name: 'AdminCharacteristicList',
                                component: () =>
                                    import('../admin/components/CharacteristicList.vue'),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'authorizationGroups/:accountId',
                                name: 'AdminAuthorizationGroupTable',
                                component: () =>
                                    import(
                                        '../admin/components/tables/AuthorizationGroupTable.vue'
                                    ),
                                meta: {role: UserView.ROLE_MANAGER},
                            },
                            {
                                path: 'license/:account',
                                name: 'AdminLicenseWrapper',
                                component: () =>
                                    import('../admin/components/license/LicenseWrapper.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                                children: [
                                    {
                                        path: 'overview',
                                        name: 'AdminLicenseOverview',
                                        component: () =>
                                            import(
                                                '../admin/components/license/LicenseOverview.vue'
                                            ),
                                    },
                                    {
                                        path: 'pricing',
                                        name: 'AdminPricingTable',
                                        component: () =>
                                            import('../admin/components/license/PricingTable.vue'),
                                    },
                                ],
                            },
                            {
                                path: 'hr/:accountId',
                                name: 'AdminHrOverview',
                                component: () => import('../admin/components/kombo/HrOverview.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'auditLog',
                                name: 'AdminAuditLog',
                                component: () =>
                                    import('../admin/components/auditLogs/AuditLogs.vue'),
                                meta: {role: UserView.ROLE_ADMIN},
                            },
                            {
                                path: 'notifications',
                                meta: {role: UserView.ROLE_SUPERADMIN},
                                children: [
                                    {
                                        path: 'list',
                                        name: 'AdminNotificationsList',
                                        component: () =>
                                            import(
                                                '../admin/components/notifications/NotificationsList.vue'
                                            ),
                                    },
                                    {
                                        path: 'add',
                                        name: 'AdminNotificationsAdd',
                                        component: () =>
                                            import(
                                                '../admin/components/notifications/NotificationsAdd.vue'
                                            ),
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../frontstage/components/security/Login.vue'),
        beforeEnter: () => {
            const {isAuthenticated} = useUserStore();
            if (isAuthenticated) {
                return {name: 'Home'};
            }
        },
        meta: {public: true},
    },
    {
        path: '/callback',
        name: 'CallBack',
        component: () => import('../frontstage/components/security/Login.vue'),
        meta: {public: true},
        beforeEnter: authenticationCallbackRoute,
    },
    {
        path: '/azure',
        name: 'Azure',
        component: () => import('../frontstage/components/security/Login.vue'),
        meta: {public: true},
    },
    {
        path: '/paymentCanceled',
        name: 'PaymentCanceled',
        meta: {public: true},
        beforeEnter: PaymentCanceled,
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../frontstage/components/security/ForgotPassword.vue'),
        meta: {public: true},
    },
    {
        path: '/forgot-password-confirm',
        name: 'ForgotPasswordConfirm',
        component: () => import('../frontstage/components/security/ForgotPasswordConfirm.vue'),
        meta: {public: true},
    },
    {
        path: '/register',
        name: 'RegisterOverview',
        component: () => import('../frontstage/components/security/RegisterOverview.vue'),
        meta: {public: true},
    },
    {
        path: '/register-user',
        name: 'RegisterUser',
        component: () => import('../frontstage/components/security/RegisterUser.vue'),
        meta: {public: true},
    },
    {
        path: '/register-account',
        name: 'RegisterAccount',
        component: () => import('../frontstage/components/security/RegisterAccount.vue'),
        meta: {public: true},
    },
    {
        path: '/register-account-survey',
        name: 'RegisterAccountSurvey',
        component: () => import('../frontstage/components/security/RegisterAccountSurvey.vue'),
        meta: {public: true},
    },
    {
        path: '/register-account-confirm',
        name: 'RegisterAccountConfirm',
        component: () => import('../frontstage/components/security/RegisterAccountConfirm.vue'),
        meta: {public: true},
    },
    {
        path: '/resend-sso-verification',
        name: 'ResendSSOVerification',
        beforeEnter: resendSSOVerification,
        meta: {public: true},
    },
    {
        path: '/verify-sso/:token',
        name: 'VerifySSO',
        component: () => import('../frontstage/components/security/VerifySSOLandingPage.vue'),
        meta: {public: true},
    },
    {
        path: '/reset-password/:userId',
        name: 'ResetPassword',
        component: () => import('../frontstage/components/security/ResetPassword.vue'),
        props: true,
        meta: {public: true},
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('../frontstage/components/LogoutPage.vue'),
        meta: {
            hideNavbar: true,
        },
    },
    {
        path: '/logout-microsoft',
        name: 'LogoutMicrosoft',
        beforeEnter: logoutMicrosoft,
    },
    {
        path: '/confirm-user',
        name: 'ConfirmUser',
        meta: {public: true},
        beforeEnter: confirmUser,
    },
    {
        path: '/account-select',
        name: 'AccountSelect',
        component: () => import('../frontstage/components/security/AccountSelect.vue'),
        props: route => ({accounts: JSON.parse(route.query.accountsString)}),
        meta: {public: true},
    },
    {
        path: '/kiosk-mode',
        name: 'KioskModeCode',
        component: () => import('../frontstage/components/kioskMode/KioskModeCode.vue'),
        meta: {hideNavbar: true, skipTokenRefresh: true},
    },
    {
        path: '/kiosk-mode/:roomId',
        name: 'KioskModeRoom',
        component: () => import('../frontstage/components/kioskMode/KioskModeRoom.vue'),
        props: true,
        meta: {hideNavbar: true, skipTokenRefresh: true},
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: () => import('../shared/components/NotFound.vue'),
    },
    {
        name: 'GoogleCallback',
        path: '/auth/google/callback',
    },
];

export default createRouter({
    history: createWebHistory(`/${getCurrentLocale()}/`),
    routes: wrapRouteGuards(routes),
    linkActiveClass: 'link-active',
});
